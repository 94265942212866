import { PayloadAction } from "@reduxjs/toolkit";
import { UserInitialStateType } from "../../types/initialStateType";
import { UserTaskType } from "../../types/userTasksTypes";
import { updateObjectDataByNewDataContainsNullAndUndefined } from "../../../../utils/updateObjectDataByNewDataContainsNullAndUndefined";
import { selectTasksArrayForAddUpdateOrDeleteOperation } from "./services";

const createTaskReducer = (
  state: UserInitialStateType,
  action: PayloadAction<UserTaskType>
) => {
  const taskArray = selectTasksArrayForAddUpdateOrDeleteOperation({
    userTasks: state.userTasks,
    executionTime: action.payload.executionTime,
  });
  if (!taskArray) {
    return;
  }
  taskArray.push(action.payload);
};

const updateTaskReducer = (
  state: UserInitialStateType,
  action: PayloadAction<UserTaskType>
) => {
  const userTasksArrays = Object.values(state.userTasks);

  outer: for (let i = 0; i < userTasksArrays.length; i++) {
    let updatedTask: UserTaskType | undefined = undefined;

    for (let j = 0; j < userTasksArrays[i].length; j++) {
      if (userTasksArrays[i][j].id === action.payload.id) {
        updatedTask = userTasksArrays[i][j];

        updateObjectDataByNewDataContainsNullAndUndefined({
          data: updatedTask,
          newUpdateData: action.payload,
        });

        // CHANGE TASK'S ARRAY IF NESSESARY
        if (updatedTask.executionTime !== action.payload.executionTime) {
          userTasksArrays[i].splice(i, 1);

          const newTaskArray = selectTasksArrayForAddUpdateOrDeleteOperation({
            userTasks: state.userTasks,
            executionTime: action.payload.executionTime,
          });

          newTaskArray?.push(updatedTask);
        }

        break outer;
      }
    }
  }
};

const deleteTaskReducer = (
  state: UserInitialStateType,
  action: PayloadAction<UserTaskType>
) => {
  const taskArray = selectTasksArrayForAddUpdateOrDeleteOperation({
    userTasks: state.userTasks,
    executionTime: action.payload.executionTime,
  });
  if (!taskArray) {
    return;
  }

  for (let i = 0; i < taskArray.length; i++) {
    if (taskArray[i].id === action.payload.id) {
      taskArray.splice(i, 1);
      break;
    }
  }
};

export const userTasksReducers = {
  createTaskReducer,
  updateTaskReducer,
  deleteTaskReducer,
};
