import { PayloadAction } from "@reduxjs/toolkit";
import { PositionInitialStateType } from "../../types/initialStateType";
import { PositionType } from "../../types/positionTypes";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { findElementInListUsingBuiltInFindMethod } from "../../../../utils/findElementInListUsingBuiltInFindMethod";
import { deletListItemUsintForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";

const getPositionsListFulfilledExtraReducer = (
  state: PositionInitialStateType,
  action: PayloadAction<PositionType[]>
) => {
  state.positionList = action.payload;
};

const createPositionFulfilledExtraReducer = (
  state: PositionInitialStateType,
  action: PayloadAction<PositionType>
) => {
  state.positionList?.push(action.payload);
};

const updatePositionFulfilledExtraReducer = (
  state: PositionInitialStateType,
  action: PayloadAction<PositionType>
) => {
  if (!state.positionList) {
    return;
  }

  const updatedPositon = findElementInListUsingBuiltInFindMethod({
    list: state.positionList,
    lookForParam: "id",
    lookForParamValue: action.payload.id,
  });
  if (!updatedPositon) {
    return;
  }

  updatedPositon.positionName = action.payload.positionName;
};

const deletePositionFulfilledExtraReducer = (
  state: PositionInitialStateType,
  action: PayloadAction<CreateUpdateDeleteReturnOneValueType<number>>
) => {
  if (!state.positionList) {
    return;
  }
  const deletedPositionAndUpdatedPositionsArray =
    deletListItemUsintForAndSpliceMethods({
      list: state.positionList,
      filterParam: "id",
      filterParamValue: action.payload.identifier,
    });
  if (!deletedPositionAndUpdatedPositionsArray) {
    return;
  }

  state.positionList = deletedPositionAndUpdatedPositionsArray.updatedArray;
};

export const positionFulfilledExtraReducers = {
  getPositionsListFulfilledExtraReducer,
  createPositionFulfilledExtraReducer,
  updatePositionFulfilledExtraReducer,
  deletePositionFulfilledExtraReducer,
};
