export const deletListItemUsintForAndSpliceMethods = <
  T extends { [key: string]: any }
>(args: {
  list: Array<T>;
  filterParam: string;
  filterParamValue: number | string;
}): { deletedElement: T | undefined; updatedArray: Array<T> } | undefined => {
  /*
    Deletes required element from array of objects.
    Do not mutate incoming array.

    list: array of objects;
    filterParam: name of list object's field
    filterParamValue: value of object's field that will be used
        for object deletion. Must be unique string or number.

    Function deletes only one alement per time.

    Returns object with DEEP copy removed element and updated array that is
    the deep copy of incoming array.

    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    ALL RETURNED ELEMENTS ARE DEEP COPIES OF ORIGINAL DATA.
    DO NOT USE THEM TO UPDATE ORIGINAL DATA.
    COPIES GOT BY JSON STRINGIFY | JSON PARCE FUNCTIONS, SO
    USE THIS FUNCTION ONLY FOR OBJECTS CONTAINS PRIMITEVE TYPES
    LIKE STRINGS, NUMBERS OR BOOLEANS.

    BE CAREFULL WITH OBJECTS CONTAINING MORE COMPLEX TYPES LIKE DATE.
    */

  if (!args.list.length) {
    return;
  }

  if (!(args.list[0] instanceof Object)) {
    throw new Error("invalid list item type. List item must be an object");
  }

  if (!args.list || !args.filterParam === undefined) {
    throw new Error("Arguments can not be undefined");
  }

  const copyOfUpdatedList: Array<T> = JSON.parse(JSON.stringify(args.list));

  let deletedElement: T | undefined = undefined;
  for (let i = 0; i < copyOfUpdatedList.length; i++) {
    if (copyOfUpdatedList[i][args.filterParam] === args.filterParamValue) {
      deletedElement = copyOfUpdatedList.splice(i, 1)[0];
      break;
    }
  }
  return { deletedElement: deletedElement, updatedArray: copyOfUpdatedList };
};
