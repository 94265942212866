import { axiosIntsance } from "../../../api/httpCommon";
import camelize from "../../../utils/camelize";
import snakeize from "../../../utils/snakeize";
import {
  CreateLeadAndMainContactType,
  CreateLeadAndContactResponseType,
  UpdateLeadRequestType,
  GetLeadAndAdditionalDataResponseType,
  LeadsListAndPaginationDataType,
} from "../types/leadTypes";
import { CreateUpdateDeleteReturnOneValueType } from "../../../commonTypes/createUpdateDeleteReturnOneValueTypes";

const commonPrefix = "lead";

const getLeadList = async (args: {
  pageNumber: number;
  limit: number;
  leadName?: string;
}): Promise<LeadsListAndPaginationDataType> => {
  const leadName = args.leadName ? `&lead_name=${args.leadName}` : "";

  const response = await axiosIntsance.get(
    `${commonPrefix}/leads?page_number=${args.pageNumber}&limit=${args.limit}${leadName}`
  );
  return camelize(response.data);
};

const getLead = async (args: {
  leadId: number;
}): Promise<GetLeadAndAdditionalDataResponseType> => {
  const response = await axiosIntsance.get(
    `${commonPrefix}/get-lead/${args.leadId}`
  );
  const camelizedLead = camelize(response.data);

  return camelizedLead;
};

const createLead = async (
  args: CreateLeadAndMainContactType
): Promise<CreateLeadAndContactResponseType> => {
  const snakeizedData = snakeize(args);

  const response = await axiosIntsance.post(
    `${commonPrefix}/create-lead`,
    snakeizedData
  );

  return response.data;
};

const updateLead = async (args: {
  updateData: UpdateLeadRequestType;
  updatedEntityId: number;
  recordsContainerId: number;
}): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const snakeizedLeadData = snakeize(args.updateData);

  const response = await axiosIntsance.patch(
    `${commonPrefix}/update-lead-data/${args.updatedEntityId}?records_container_id=${args.recordsContainerId}`,
    snakeizedLeadData
  );
  return response.data;
};

const deleteLead = async (args: {
  leadId: number;
}): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const response = await axiosIntsance.delete(
    `${commonPrefix}/delete-lead/${args.leadId}`
  );
  return response.data;
};

export const leadRequests = {
  getLeadList,
  getLead,
  createLead,
  updateLead,
  deleteLead,
};
