import camelize from "../../../utils/camelize";
import { axiosIntsance } from "../../../api/httpCommon";
import snakeize from "../../../utils/snakeize";
import {
  CreateRecordWithRelatedEntityType,
  DeleteRecordWithRelatedEntityType,
  RecordType,
  UpdateRecordWithRelatedEntityType,
} from "../types/recordTypes";

const createNoteOrTaskRecord = async (
  args: CreateRecordWithRelatedEntityType
): Promise<RecordType> => {
  const snakeizedData = snakeize(args.recordData);

  const requestURL =
    `record/create-record` +
    `?related_entity_name=${args.relatedEntityData.relatedEntityName}` +
    `&related_entity_id=${args.relatedEntityData.relatedEntityId}` +
    `&related_entity_type=${args.relatedEntityData.relatedEntityType}`;

  const response = await axiosIntsance.post(requestURL, snakeizedData);
  const camelizedData = camelize(response.data);

  return camelizedData;
};

const updateRecord = async (
  args: UpdateRecordWithRelatedEntityType
): Promise<RecordType> => {
  const snakeizedData = snakeize(args.recordData);

  const requestURL =
    `/record/update-record/${args.recordId}` +
    `?related_entity_name=${args.relatedEntityData.relatedEntityName}` +
    `&related_entity_id=${args.relatedEntityData.relatedEntityId}` +
    `&related_entity_type=${args.relatedEntityData.relatedEntityType}`;

  const response = await axiosIntsance.patch(requestURL, snakeizedData);

  return response.data;
};

const deleteRecord = async (
  args: DeleteRecordWithRelatedEntityType
): Promise<RecordType> => {
  const requestURL =
    `/record/delete-record/${args.recordData.recordId}` +
    `?related_entity_name=${args.relatedEntityData.relatedEntityName}` +
    `&related_entity_id=${args.relatedEntityData.relatedEntityId}` +
    `&related_entity_type=${args.relatedEntityData.relatedEntityType}`;

  const response = await axiosIntsance.delete(requestURL);

  return response.data;
};

export const recordRequests = {
  createNoteOrTaskRecord,
  updateRecord,
  deleteRecord,
};
