import { PayloadAction } from "@reduxjs/toolkit";
import { findElementInListUsingBuiltInFindMethod } from "../../../../utils/findElementInListUsingBuiltInFindMethod";
import { updateObjectDataByNewDataContainsNullAndUndefined } from "../../../../utils/updateObjectDataByNewDataContainsNullAndUndefined";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { deletListItemUsintForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";
import { RecordInitialStateType } from "../../types/initialStateType";
import {
  RecordType,
  UpdateRecordWithRelatedEntityType,
} from "../../types/recordTypes";

const createRecordExtraReducer = (
  state: RecordInitialStateType,
  action: PayloadAction<RecordType>
) => {
  if (!state.records || !state.containerId || !state.relatedEntityData) {
    throw new Error("Record data does not exist");
  }

  state.records.push(action.payload);
};

const updateRecordExtraReducer = (
  state: RecordInitialStateType,
  action: PayloadAction<
    Omit<UpdateRecordWithRelatedEntityType, "relatedEntityData">
  >
) => {
  try {
    if (!state.records || !state.containerId || !state.relatedEntityData) {
      throw new Error("Record data does not exist");
    }

    const updatedRecord: RecordType | null =
      findElementInListUsingBuiltInFindMethod({
        list: state.records,
        lookForParam: "id",
        lookForParamValue: action.payload.recordId,
      });

    if (updatedRecord === null) {
      return;
    }

    updateObjectDataByNewDataContainsNullAndUndefined({
      data: updatedRecord,
      newUpdateData: action.payload.recordData,
    });
  } catch (error) {
    console.error(error);
  }
};

const deleteRecordExtraReducer = (
  state: RecordInitialStateType,
  action: PayloadAction<CreateUpdateDeleteReturnOneValueType<number>>
) => {
  if (state === undefined) {
    throw new Error("Record data does not exist");
  }
  if (!state.records || !state.records.length) {
    return;
  }

  const updatedRecordsList = deletListItemUsintForAndSpliceMethods<RecordType>({
    list: state.records,
    filterParam: "id",
    filterParamValue: action.payload.identifier,
  });

  if (updatedRecordsList) {
    state.records = updatedRecordsList.updatedArray;
  }
};

export const recordExtraReducers = {
  createRecordExtraReducer,
  updateRecordExtraReducer,
  deleteRecordExtraReducer,
};
