import { axiosIntsance } from "../../../api/httpCommon";
import { CreateUpdateDeleteReturnOneValueType } from "../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import snakeize from "../../../utils/snakeize";
import {
  CreateFunnelStepType,
  FunnelStepUuidAndNameType,
  UpdateFunnelStepsOrderType,
  FunnelStepWithRelatedEntitiesType,
  FunnelStepWithoutRelatedEntitiesType,
} from "../types/funnelStepTypes";
import camelize from "../../../utils/camelize";
import { UUID } from "crypto";

const getFunnelStepsWithRelatedLeads = async (
  funnelId: number
): Promise<FunnelStepWithRelatedEntitiesType[]> => {
  const response = await axiosIntsance.get(
    `funnel/get-funnel-steps-with-related-entities/${funnelId}`
  );

  const funnelStepsWithRelatedLeads = camelize(response.data);

  return funnelStepsWithRelatedLeads;
};

const createFunnelStep = async (
  args: CreateFunnelStepType
): Promise<FunnelStepWithoutRelatedEntitiesType[]> => {
  const snakeizedData = snakeize(args);

  const response = await axiosIntsance.post(
    "funnel/create-funnel-step",
    snakeizedData
  );

  const camelizedData = camelize(response.data);

  return camelizedData;
};

const updateFunnelStepsOrder = async (
  args: UpdateFunnelStepsOrderType
): Promise<FunnelStepWithoutRelatedEntitiesType[]> => {
  const snakezedUpdateFunnelData = snakeize({
    funnelId: args.funnelId,
    prevStepUuid: args.prevStepUuid,
    nextStepUuid: args.nextStepUuid,
  });

  const response = await axiosIntsance.patch(
    `funnel/update-funnel-step/order/${args.updateFunnelStepUuid}`,
    snakezedUpdateFunnelData
  );

  const camelizedResponse = camelize(response.data);

  return camelizedResponse;
};

const updateFunnelStepName = async (
  args: FunnelStepUuidAndNameType
): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const response = await axiosIntsance.patch(
    `/funnel/update-funnel-step/name/${args.funnelStepUuid}`,
    { funnel_step_name: args.funnelStepName }
  );

  return response.data;
};

export const deleteFunnelStep = async (
  args: CreateUpdateDeleteReturnOneValueType<UUID>
): Promise<FunnelStepWithoutRelatedEntitiesType[]> => {
  const response = await axiosIntsance.delete(
    `/funnel/delete-funnel-step/${args.identifier}/`
  );

  const camelizedResponse = camelize(response.data);

  return camelizedResponse;
};

export const funnelStepRequests = {
  getFunnelStepsWithRelatedLeads,
  createFunnelStep,
  updateFunnelStepsOrder,
  updateFunnelStepName,
  deleteFunnelStep,
};
