import { PayloadAction } from "@reduxjs/toolkit";
import { UserInitialStateType } from "../../types/initialStateType";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { deletListItemUsintForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";
import { findElementInListUsingBuiltInFindMethod } from "../../../../utils/findElementInListUsingBuiltInFindMethod";
import { updateObjectDataByNewDataContainsNullAndUndefined } from "../../../../utils/updateObjectDataByNewDataContainsNullAndUndefined";
import { CreateUserAsyncThunkType } from "../../types/userTypes";
import {
  UpdateUserDataAdminAsyncThunkRequestType,
  UserListPreviewsAndPositionsListAdminType,
} from "../../types/userAdminTypes";

const getUsersPreviewsAndPositionsListExtraReducer = (
  state: UserInitialStateType,
  action: PayloadAction<UserListPreviewsAndPositionsListAdminType>
) => {
  state.userAdminData = action.payload;
};

const createUserFulfilledExtraReducer = (
  state: UserInitialStateType,
  action: PayloadAction<CreateUserAsyncThunkType>
) => {
  if (!state.userAdminData.userPreviewsList) {
    return;
  }

  state.userAdminData.userPreviewsList.push({
    username: action.payload.username,
    name: action.payload.name,
    isActive: true,
    position: action.payload.position,
    isAdmin: action.payload.isAdmin,
    phoneNumber: null,
    tgUsername: null,
  });
};

const deleteUserFulfilledExtraReducer = (
  state: UserInitialStateType,
  action: PayloadAction<CreateUpdateDeleteReturnOneValueType<string>>
) => {
  if (!state.userAdminData.userPreviewsList) {
    return;
  }

  const deletedUserAndUpdatedArray = deletListItemUsintForAndSpliceMethods({
    filterParam: "username",
    filterParamValue: action.payload.identifier,
    list: state.userAdminData.userPreviewsList,
  });

  if (!deletedUserAndUpdatedArray) {
    return;
  }

  state.userAdminData.userPreviewsList =
    deletedUserAndUpdatedArray.updatedArray;
};

const updateUserPreviewAtUsersPreviewsList = (
  state: UserInitialStateType,
  action: PayloadAction<UpdateUserDataAdminAsyncThunkRequestType>
) => {
  if (!state.userAdminData.userPreviewsList) {
    return;
  }

  const updatedListItem = findElementInListUsingBuiltInFindMethod({
    list: state.userAdminData.userPreviewsList,
    lookForParam: "username",
    lookForParamValue: action.payload.username,
  });
  if (!updatedListItem) {
    return;
  }

  updateObjectDataByNewDataContainsNullAndUndefined({
    data: updatedListItem,
    newUpdateData: {
      isActive: action.payload.isActive,
      isAdmin: action.payload.isAdmin,
      position: action.payload.position,
    },
  });
};

export const userAdminExtraReducers = {
  getUsersPreviewsAndPositionsListExtraReducer,
  createUserFulfilledExtraReducer,
  deleteUserFulfilledExtraReducer,
  updateUserPreviewAtUsersPreviewsList,
};
