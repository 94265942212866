import { axiosIntsance } from "../../../api/httpCommon";
import snakeize from "../../../utils/snakeize";
import camelize from "../../../utils/camelize";
import { CreateUpdateDeleteReturnOneValueType } from "../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { RelatedEntityArgsType } from "../../../commonTypes/relatedEntityTypes";
import {
  UpdateFunnelType,
  FunnelType,
  CreateFunnelType,
  FunnelWithRelatedFunnelStepsWithoutRelatedEntitiesType,
} from "../types/funnelTypes";

const getFunnelsList = async (
  args: RelatedEntityArgsType
): Promise<FunnelType[]> => {
  const response = await axiosIntsance.get(
    `funnel/get-funnels?related_entity=${args.relatedEntity}`
  );

  const camelizedData = camelize(response.data);

  return camelizedData;
};

const getFunnelWithRelatedFunnelStepsWithoutRelatedEntities = async (
  funnelId: number
): Promise<FunnelWithRelatedFunnelStepsWithoutRelatedEntitiesType> => {
  const response = await axiosIntsance.get(
    `/funnel/get-funnel-with-related-funnel-steps/${funnelId}`
  );

  const funnelWithRelatedFunnelStepsWithoutRelatedLeads = camelize(
    response.data
  );

  return funnelWithRelatedFunnelStepsWithoutRelatedLeads;
};

export const createFunnel = async (
  createFunnelData: CreateFunnelType
): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const snakeizedData = snakeize(createFunnelData);

  const response =
    await axiosIntsance.post<CreateUpdateDeleteReturnOneValueType<number>>(
      `funnel/create-funnel`,
      snakeizedData
    );

  return response.data;
};

export const updateFunnel = async (args: {
  funnelId: number;
  funnelData: UpdateFunnelType;
}): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const snakeizedData = snakeize(args.funnelData);

  const response =
    await axiosIntsance.patch<CreateUpdateDeleteReturnOneValueType<number>>(
      `funnel/update-funnel/${args.funnelId}`,
      snakeizedData
    );

  return response.data;
};

export const deleteFunnel = async (args: {
  funnelId: number;
}): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const response = await axiosIntsance.delete(
    `funnel/delete-funnel/${args.funnelId}`
  );

  return response.data;
};

export const funnelRequests = {
  getFunnelsList,
  getFunnelWithRelatedFunnelStepsWithoutRelatedEntities,
  createFunnel,
  updateFunnel,
  deleteFunnel,
};
