import { PayloadAction } from "@reduxjs/toolkit";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { deletListItemUsintForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";
import { findElementInListUsingBuiltInFindMethod } from "../../../../utils/findElementInListUsingBuiltInFindMethod";
import { updateObjectDataByNewDataContainsNullAndUndefined } from "../../../../utils/updateObjectDataByNewDataContainsNullAndUndefined";
import { LeadPreviewType } from "../../types/leadTypes";
import { LeadInitialStateType } from "../../types/initialState";
import {
  FunnelOrFunnelStepHasBeedDeletedType,
  LeadFunnelStepType,
  LeadFunnelType,
} from "../../types/leadFunnelTypes";
import { UUID } from "crypto";

const addNewLeadToLeadListSSUReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<LeadPreviewType>
) => {
  /*
     All reducers with SSU suffix are used for websocket notification system(SSU).
     DO NOT! use this reducer at components layer.
  
     Adds new lead at the end of lead list.
     */
  if (!state.leadListData.leadsList) {
    throw new Error(
      "Lead list is undefined. Can't update it. Reducer has been called at the wrond place."
    );
  }
  state.leadListData.leadsList.push({ ...action.payload, funnelStep: null });
  // state.leadsList!.push(action.payload);
};

const removeDeletedLeadFromLeadListSSUReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<CreateUpdateDeleteReturnOneValueType<number>>
) => {
  /*
     All reducers with SSU suffix are used for websocket notification system(SSU).
     DO NOT! use this reducer at components layer.
  
     Removes deleted lead from leads list
     */
  if (!state.leadListData.leadsList) {
    throw new Error(
      "Lead list is undefined. Can't update it. Reducer has been called at the wrond place."
    );
  }
  const deletedElementAndUpdatedArray = deletListItemUsintForAndSpliceMethods({
    list: state.leadListData.leadsList,
    filterParam: "id",
    filterParamValue: action.payload.identifier,
  });
  if (deletedElementAndUpdatedArray) {
    state.leadListData.leadsList = deletedElementAndUpdatedArray.updatedArray;
  }
};

const updateLeadListItemSSUReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<Omit<LeadPreviewType, "createdAt" | "personsInCharge">>
) => {
  /*
     All reducers with SSU suffix are used for websocket notification system(SSU).
     DO NOT! use this reducer at components layer.
  
     Updates lead preview at leads list. Is not used at components layer.
     */
  if (!state.leadListData.leadsList) {
    throw new Error(
      "Lead list is undefined. Reducer has been called at the wrond place."
    );
  }

  const leadPreviewForUpdate =
    findElementInListUsingBuiltInFindMethod<LeadPreviewType>({
      list: state.leadListData.leadsList,
      lookForParam: "id",
      lookForParamValue: action.payload.id,
    });

  if (leadPreviewForUpdate === null) {
    return;
  }
  updateObjectDataByNewDataContainsNullAndUndefined({
    data: leadPreviewForUpdate,
    newUpdateData: action.payload,
  });
};

const updateLeadItemFunnelAndFunnelStepNamesSSUReducer = (
  state: LeadInitialStateType,
  action: PayloadAction<
    LeadFunnelType | LeadFunnelStepType | FunnelOrFunnelStepHasBeedDeletedType
  >
) => {
  // LEAD LIST IS EMPTY
  if (!state.leadListData.leadsList) {
    return;
  }

  // FUNNEL NAME HAS BEEN CHANGED
  if ((action.payload as LeadFunnelType).funnelName !== undefined) {
    state.leadListData.leadsList.forEach((lead) => {
      if (
        lead.funnelStep &&
        lead.funnelStep.funnel.funnelId ===
          (action.payload as LeadFunnelType).funnelId
      ) {
        lead.funnelStep.funnel.funnelName = (
          action.payload as LeadFunnelType
        ).funnelName;
      }
    });
    return;
  }

  //   FUNNEL STEP NAME HAS BEEN CHANGED
  if ((action.payload as LeadFunnelStepType).funnelStepName !== undefined) {
    state.leadListData.leadsList.forEach((lead) => {
      if (
        lead.funnelStep &&
        lead.funnelStep.funnelStepUuid ===
          (action.payload as LeadFunnelStepType).funnelStepUuid
      ) {
        lead.funnelStep.funnelStepName = (
          action.payload as LeadFunnelStepType
        ).funnelStepName;
      }
    });
    return;
  }

  //   FUNNEL OR FUNNEL STEP HAS BEEN DELETED
  if (
    (action.payload as FunnelOrFunnelStepHasBeedDeletedType).nullValue !==
    undefined
  ) {
    if (
      (action.payload as FunnelOrFunnelStepHasBeedDeletedType).funnelId !==
      undefined
    ) {
      // funnel has been deleted
      state.leadListData.leadsList.forEach((lead) => {
        if (
          lead.funnelStep &&
          lead.funnelStep.funnel.funnelId ===
            ((action.payload as FunnelOrFunnelStepHasBeedDeletedType)
              .funnelId as number)
        ) {
          lead.funnelStep = null;
        }
      });
    } else if (
      (action.payload as FunnelOrFunnelStepHasBeedDeletedType)
        .funnelStepUuid !== undefined
    ) {
      // funnel step has been deleted
      state.leadListData.leadsList.forEach((lead) => {
        if (
          lead.funnelStep &&
          lead.funnelStep.funnelStepUuid ===
            ((action.payload as FunnelOrFunnelStepHasBeedDeletedType)
              .funnelStepUuid as UUID)
        ) {
          lead.funnelStep = null;
        }
      });
    }
  }
};
//   state.leadsList.forEach((lead) => {
//     if (!lead.funnelStep || lead.funnelStep.funnel.id !== action.payload.id) {
//       return;
//     }

//     if (
//       payloadIsFunnelOrFunnelStepHasBeedDeletedTypePredicate(action.payload)
//     ) {
//       lead.funnelStep = action.payload.nullValue;
//       return;
//     }

//     if (payloadIsleadFunnelTypePredicate(action.payload)) {
//       lead.funnelStep.funnel.funnelName = action.payload.funnelName;
//       return;
//     }

//     if (payloadIsleadFunnelStepTypePredicate(action.payload)) {
//       lead.funnelStep.stepName = action.payload.stepName;
//       return;
//     }
//   });

export const leadListReducers = {
  addNewLeadToLeadListSSUReducer,
  removeDeletedLeadFromLeadListSSUReducer,
  updateLeadListItemSSUReducer,
  updateLeadItemFunnelAndFunnelStepNamesSSUReducer,
};
