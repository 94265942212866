import { axiosIntsance } from "../../../api/httpCommon";
import { CreateUpdateDeleteReturnOneValueType } from "../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import camelize from "../../../utils/camelize";
import snakeize from "../../../utils/snakeize";
import {
  CreateDealResponseType,
  CreateDealType,
  DeleteDealType,
  GetDealResponseType,
  UpdateDealRequestType,
} from "../types/dealTypes";

const getDealById = async (args: {
  dealId: number;
}): Promise<GetDealResponseType> => {
  const response = await axiosIntsance.get(`/deal/get-deal/${args.dealId}`);

  const camelizedData = camelize(response.data);

  return camelizedData;
};

export const createDeal = async (
  args: CreateDealType
): Promise<CreateDealResponseType> => {
  const snakeizedData = snakeize(args);

  const response = await axiosIntsance.post(
    `/deal/create-deal?lead_records_container_id=${args.leadRecordsContainerId}`,
    snakeizedData
  );
  const camelizedData = camelize(response.data);

  return camelizedData;
};

export const updateDeal = async (args: {
  updateData: UpdateDealRequestType;
  updatedEntityId: number;
  recordsContainerId: number;
}): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const snakeizedData = snakeize(args.updateData);

  const response = await axiosIntsance.patch(
    `/deal/update-deal/${args.updatedEntityId}?deal_records_container_id=${args.recordsContainerId}`,
    snakeizedData
  );

  return response.data;
};

export const deleteDeal = async (
  args: DeleteDealType
): Promise<CreateUpdateDeleteReturnOneValueType<number>> => {
  const response = await axiosIntsance.delete(
    `/deal/delete-deal/${args.id}/?lead_id=${args.leadId}&funnel_id=${args.funnelId}&funnel_step_uuid=${args.funnelStepUuid}`
  );

  return response.data;
};

export const dealRequests = {
  getDealById,
  createDeal,
  updateDeal,
  deleteDeal,
};
