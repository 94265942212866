import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateUpdateDeleteReturnOneValueType } from "../../../../commonTypes/createUpdateDeleteReturnOneValueTypes";
import { clearDataFromNullUndefinedFields } from "../../../../utils/clearDataFromNullUndefinedFields";
import { recordRequests } from "../../requests/recordRequests";
import { AppDispatch, RootState } from "../../../../store";
import { createTask, deleteTask, updateTask } from "../../../user/store/slice";
import { createUserTaskUpdateUtil } from "./utils";
import { showNotification } from "../../../notification/store/slice";
import { NotificationTypeEnum } from "../../../notification/types/notificationTypes";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";
import {
  RecordType,
  CreateRecordWithRelatedEntityType,
  DeleteRecordWithRelatedEntityType,
  UpdateRecordWithRelatedEntityType,
} from "../../types/recordTypes";

export const createRecordAsyncThunk = createAsyncThunk<
  RecordType,
  CreateRecordWithRelatedEntityType,
  {
    rejectValue: RejectWithValueErrorType;
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  "record/createRecord",
  async (args, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await recordRequests.createNoteOrTaskRecord(args);

      createUserTaskUpdateUtil({
        dispatch: dispatch,
        getState: getState,
        record: response,
        reducer: createTask,
        relatedEntityData: args.relatedEntityData,
      });

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Запись создана.",
        })
      );

      return response;
    } catch (error: any) {
      if (
        error.response.status === 400 &&
        error.response.data.error ===
          "Type 'log_record' is a system related_entity_type. Do not use is for message creation"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText:
              "Неверный тип записи. Для устранения этой ошибки свяжитесь с администратором.",
          })
        );
      }
      if (
        error.response.status === 400 &&
        error.response.data.error ===
          "Type task must have execution time and person in charge is not None"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText:
              "Неверный тип записи. Для устранения этой ошибки свяжитесь с администратором.",
          })
        );
      }
      if (
        error.response.status === 409 &&
        error.response?.data?.error ===
          "Row with foreign key you trying to insert does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.error,
            notificationText: "Связаный лид или сделка были удалены.",
          })
        );
      }

      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
      });
    }
  }
);

export const updateRecordAsyncThunk = createAsyncThunk<
  Omit<UpdateRecordWithRelatedEntityType, "relatedEntityData">,
  UpdateRecordWithRelatedEntityType,
  {
    rejectValue: RejectWithValueErrorType;
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  "record/updateRecord",
  async (args, { rejectWithValue, dispatch, getState }) => {
    try {
      const clearedFromNullAndUndefinedRecordData =
        clearDataFromNullUndefinedFields({ dataObject: args.recordData });

      args.recordData = clearedFromNullAndUndefinedRecordData;

      const response = await recordRequests.updateRecord(args);

      createUserTaskUpdateUtil({
        dispatch: dispatch,
        getState: getState,
        record: response,
        reducer: updateTask,
        relatedEntityData: args.relatedEntityData,
      });

      dispatch(
        showNotification({
          notificationType: NotificationTypeEnum.success,
          notificationText: "Запись обновлена.",
        })
      );

      return args;
    } catch (error: any) {
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText:
              "Запись была удалена другим пользователем. Обновите страницу, что бы  получить актуальную информацию.",
          })
        );
      }
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
        entityId: args.recordId,
      });
    }
  }
);

export const deleteRecordAsyncThunk = createAsyncThunk<
  CreateUpdateDeleteReturnOneValueType<number>,
  DeleteRecordWithRelatedEntityType,
  {
    rejectValue: RejectWithValueErrorType;
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  "record/deleteRecord",
  async (args, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await recordRequests.deleteRecord(args);

      createUserTaskUpdateUtil({
        dispatch: dispatch,
        getState: getState,
        record: response,
        reducer: deleteTask,
        relatedEntityData: args.relatedEntityData,
      });

      return { identifier: args.recordData.recordId };
    } catch (error: any) {
      if (
        error.response.status === 404 &&
        error.response.data.error === "Data does not exist"
      ) {
        dispatch(
          showNotification({
            notificationType: NotificationTypeEnum.warning,
            notificationText:
              "Запись уже была удалена другим пользователем. Обновите страницн, что бы получить актуальную информацию.",
          })
        );
      }
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
        errorDetails: error.response?.data?.error,
        entityId: args.recordData.recordId,
      });
    }
  }
);
