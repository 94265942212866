import { PayloadAction } from "@reduxjs/toolkit";
import { UserInitialStateType } from "../../types/initialStateType";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";
import { deletListItemUsintForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";

const deleteUpdateUserRejectedExtraReducer = (
  state: UserInitialStateType,
  action: PayloadAction<RejectWithValueErrorType | undefined>
) => {
  if (!state.userAdminData.userPreviewsList) {
    return;
  }
  if (!action.payload || !action.payload.entityId) {
    return;
  }

  const deletedUserAndUpdatedArray = deletListItemUsintForAndSpliceMethods({
    filterParam: "username",
    filterParamValue: action.payload.entityId,
    list: state.userAdminData.userPreviewsList,
  });

  if (!deletedUserAndUpdatedArray) {
    return;
  }

  state.userAdminData.userPreviewsList =
    deletedUserAndUpdatedArray.updatedArray;
};

export const userRejectedExtraReducers = {
    deleteUpdateUserRejectedExtraReducer,
};
