import { PayloadAction } from "@reduxjs/toolkit";
import { PositionInitialStateType } from "../../types/initialStateType";
import { RejectWithValueErrorType } from "../../../../commonTypes/rejectWithValueErrorType";
import { deletListItemUsintForAndSpliceMethods } from "../../../../utils/deleteListItemUsingForAndSplice";

const updateDeletePositionExtraReducer = (
  state: PositionInitialStateType,
  action: PayloadAction<RejectWithValueErrorType | undefined>
) => {
  console.log('>>>>>>>>>>');
  
  if (!state.positionList) {
    throw new Error("Custom fields has not bean loaded");
  }

  if (
    action.payload &&
    action.payload.entityId &&
    action.payload.status === 404
  ) {
    const updatedContactsList = deletListItemUsintForAndSpliceMethods({
      filterParam: "id",
      filterParamValue: action.payload.entityId,
      list: state.positionList,
    });

    if (updatedContactsList?.updatedArray) {
      state.positionList = updatedContactsList.updatedArray;
    }
  }
};

export const positionRejectedExtraReducers = {
  updateDeletePositionExtraReducer,
};
