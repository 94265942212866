import { axiosIntsance } from "../../../api/httpCommon";
import camelize from "../../../utils/camelize";
import snakeize from "../../../utils/snakeize";
import {
  UpdateUserDataAdminRequestType,
  UserListPreviewsAndPositionsListAdminType,
} from "../types/userAdminTypes";
import { CreateUserRequestType } from "../types/userTypes";

const getUsersPreviewsAndPositionsList =
  async (): Promise<UserListPreviewsAndPositionsListAdminType> => {
    const response = await axiosIntsance.get(
      "user/get-users-previews-list-and-positions-list"
    );

    const camelizedData = camelize(response.data);

    return camelizedData;
  };

const createUser = async (args: CreateUserRequestType): Promise<void> => {
  const snakeizedData = snakeize(args);

  await axiosIntsance.post("user/create", snakeizedData);
};

const updateUserDataByAdmin = async (
  args: UpdateUserDataAdminRequestType
): Promise<void> => {
  const snakeizedData = snakeize(args.updateUserData);

  await axiosIntsance.patch(
    `user/update-user-data-by-admin/${args.username}`,
    snakeizedData
  );
};

const deleteUser = async (args: { username: string }): Promise<void> => {
  await axiosIntsance.delete(`user/delete/${args.username}`);
};

export const userAdminRequests = {
  getUsersPreviewsAndPositionsList,
  createUser,
  updateUserDataByAdmin,
  deleteUser,
};
